import { Component, EventEmitter, Output } from '@angular/core';
import { addDays, constructFrom, constructNow, isAfter, isDate, startOfToday } from 'date-fns';
import { catchError, EMPTY, tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { DiaryEvent } from 'src/app/core-module/models/Diary/diary-event';
import { LocationHolder } from 'src/app/core-module/models/Locations/location-holder';
import { LocationResult } from 'src/app/core-module/models/Locations/location-result';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { DiaryService } from 'src/app/core-module/services/diary.service';
import { ReferenceService } from 'src/app/core-module/services/reference.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserService } from 'src/app/core-module/services/user.service';

@Component({
  selector: 'app-add-diary-event',
  templateUrl: './add-diary-event.component.html',
  styleUrl: './add-diary-event.component.scss'
})
export class AddDiaryEventComponent {

  public showAudit: boolean = false;
  public showLocation: boolean = false;
  public showUsers: boolean = false;
  public showFrequency: boolean = false;
  public showStart: boolean = false;
  public showEnd: boolean = false;
  public showError: boolean = false;
  public showStartDateError: boolean = false;

  public audits: AuditCollectionResult;
  public selectedAudit: FlattenedQuestionHolder;

  public allLocs: LocationResult;
  public locations: LocationHolder[] = [];
  public selectedLocations: LocationHolder[] = [];
  public topLevel: boolean = true;
  public selectedLocation: LocationHolder = new LocationHolder();

  public allUsers: BasicUser[];
  public selectedUser: BasicUser;

  public allFrequencies: DDL[];
  public selectedFrequency: DDL;

  public startDate: Date;
  public endDate: Date;
  public hasEndDate:boolean = false;

  public isSaving: boolean = false;

  public today: Date = startOfToday();

  @Output() eventAdded: EventEmitter<boolean> = new EventEmitter<boolean>;
  

  constructor(public audit:AuditService, public referenceService:ReferenceService, public users:UserService, public diary:DiaryService, public toastr:ToastrService){}

  ngOnInit() {
    this.audit.getAudits().pipe(
      tap(res => {
        this.audits = res;
        this.audits.Audits.unshift(new FlattenedQuestionHolder({AuditID: -1, AuditName:'Please Select...'}));
        this.selectedAudit = this.audits.Audits[0];
        this.showAudit = true;
      })
    ).subscribe();
  }

  selectedAuditChanged(){
    if (this.selectedAudit.AuditID == -1) return;
    this.referenceService.getLocationsByAuditID(this.selectedAudit.AuditID,1).pipe(
      tap(res =>{
        if(res!=null){
          if (res.hasOwnProperty('body')){
            this.allLocs = <LocationResult>res.body;
          } else {
            this.allLocs = <LocationResult>res;
          }
          this.showAudit = false;
          this.showLocation = true;
          this.setCurrentLocationLevel();
        }
      })
    ).subscribe();
  }

  setCurrentLocationLevel(){
    this.locations = this.allLocs.Locations.SubLocations;
    this.initialPopulation(this.locations[0]);  
    this.resetLocations()    
  }
  initialPopulation(l:LocationHolder){
    this.selectedLocations = l.SubLocations;
    this.selectedLocation = l.SubLocations[0];

  }
  chooseSublocation(l:LocationHolder){
    this.topLevel = false;
    this.selectedLocations = l.SubLocations;
  }

  selectLocation(l:LocationHolder){
    this.selectedLocation = l; 
    this.showLocation = false; 
    this.getAuditors(); 
  }

  resetLocations(){
    this.selectedLocations = this.locations;
    this.topLevel = true;
  }

  getAuditors(){
    this.diary.getDiaryUsers(this.selectedAudit.AuditID,this.selectedLocation.Location.LocationID).pipe(
      tap(res => {
        this.allUsers = res;
        this.allUsers.unshift(new BasicUser({UserID: -1, DisplayName: "Please Select..."}));
        this.selectedUser = this.allUsers[0];
        this.showUsers = true;
      })
    ).subscribe();
  }

  selectedAuditorChanged(){
    this.showUsers = false;
    this.getFrequencies();
  }

  getFrequencies(){
    this.referenceService.getFrequencies().pipe(
      tap(res => {
        this.allFrequencies = res;
        this.allFrequencies.unshift(new DDL({ID: -1, Description: "Please Select..."}));
        this.selectedFrequency = this.allFrequencies[0];
        this.showFrequency = true;
      })
    ).subscribe();
  }

  selectedFrequencyChanged(){
    this.showFrequency = false;
    this.showStart = true;
  }

  updateStartDate(){
    this.showStartDateError = false;
    if (!isDate(this.startDate)){
      this.showStartDateError = true;
      return;
    }
    this.showStart = false;
    this.showError = false;
    this.showEnd = true;
  }

  updateEndDate(){
    this.showStartDateError = false;
  }

  createEvent(){
    
    this.isSaving = true;

    let de: DiaryEvent = new DiaryEvent({
      AuditID: this.selectedAudit.AuditID,
      LocationID: this.selectedLocation.Location.LocationID,
      ScheduledUserID: this.selectedUser.UserID,
      FrequencyID: this.selectedFrequency.ID,
      StartDate: constructFrom(this.startDate,this.startDate),
      EndDate: constructFrom(this.endDate,this.endDate),
      HasEndDate: this.hasEndDate
    });

    if (de.HasEndDate && isAfter(de.StartDate, de.EndDate)){
      this.showError = true;
      this.isSaving = false;
      return;
    }

    if (!de.HasEndDate){
      de.EndDate = constructFrom(new Date(),new Date());
    }
    
    this.diary.saveEvent(de).pipe(
      tap(res => {
        if (res.body?.IsError){
          this.toastr.addToastr(new Toastr({Message: "An error occurred saving the audit. Please try again later.", Type: 3}))
          this.isSaving = false;
        } else {
          this.isSaving = false;
          this.showEnd = false;
          this.eventAdded.emit(true);
        }
      }
    ),catchError((err,obs) => {
      this.toastr.addToastr(new Toastr({Message: "An error occurred saving the audit. Please try again later.", Type: 3}))
      this.isSaving = false;
      return EMPTY;
    })
    ).subscribe();
    
  }

  backToAudit(){
    this.showAudit = true;
    this.showLocation = false;
    this.showUsers = false;
    this.showFrequency = false;
    this.showStart = false;
    this.showEnd = false;
  }

  backToLocation(){
    this.showAudit = false;
    this.showLocation = true;
    this.showUsers = false;
    this.showFrequency = false;
    this.showStart = false;
    this.showEnd = false;
  }

  backToAuditor(){
    this.showAudit = false;
    this.showLocation = false;
    this.showUsers = true;
    this.showFrequency = false;
    this.showStart = false;
    this.showEnd = false;
  }

  backToFrequency(){
    this.showAudit = false;
    this.showLocation = false;
    this.showUsers = false;
    this.showFrequency = true;
    this.showStart = false;
    this.showEnd = false;
  }

  backToStartDate(){
    this.showAudit = false;
    this.showLocation = false;
    this.showUsers = false;
    this.showFrequency = false;
    this.showStart = true;
    this.showEnd = false;
  }

}
