<div class="border-t border-grey-500 py-4">
    <div class="flex justify-between items-center">
        <app-text-box-entry class="block cursor-pointer" (click)="toggleShowOptions()" [(value)]="question.QuestionHeader" (valueChange)="questionChanged()" [isBold]="true"></app-text-box-entry>
        <button class="btn-error w-auto mt-0"><span class="material-symbols-outlined text-sm cursor-pointer px-2" (click)="delQuestion()">delete</span></button>
    </div>
    
    <div *ngIf="showOptions">
        <div class="grid grid-cols-3 lg:w-1/2 xl:w-1/2 auto-cols-min items-center" @inOutAnimation>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Body: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [isTextArea]="false" [(value)]="question.QuestionBody" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Type: </span></p>
            <app-dropdown-entry class="col-span-2 mb-2" [(value)]="question.QuestionType" (valueChange)="questionChanged()" [options]="answerTypes"></app-dropdown-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Help Text: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [isTextArea]="true" [(value)]="question.HelpText" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Number: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [(value)]="question.QuestionNumber" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Answer Required?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.Required" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Photo Required?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.PhotoRequired" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Are Comments Allowed?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.AllowComments" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is N/A Valid?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.AllowNA" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Reference?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.IsReference" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Exclude Scores?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.ExcludeScoreFromTotal" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2" *ngIf="!question.ExcludeScoreFromTotal"><span class="text-sm text-grey-900">Compliance Limit: </span></p>
            <app-number-box-entry *ngIf="!question.ExcludeScoreFromTotal" class="col-span-2 mb-2" [(value)]="question.ComplianceLimit" (valueChange)="questionChanged()"></app-number-box-entry>
            <p class=" text-right pr-2 mb-2" *ngIf="!question.ExcludeScoreFromTotal"><span class="text-sm text-grey-900">Major Compliance Limit: </span></p>
            <app-number-box-entry *ngIf="!question.ExcludeScoreFromTotal" class="col-span-2 mb-2" [(value)]="question.MajorLimit" (valueChange)="questionChanged()"></app-number-box-entry>
            <p class=" text-right pr-2 mb-2" *ngIf="!question.ExcludeScoreFromTotal"><span class="text-sm text-grey-900">Max Score: </span></p>
            <app-number-box-entry *ngIf="!question.ExcludeScoreFromTotal" class="col-span-2 mb-2" [(value)]="question.MaxScore" (valueChange)="questionChanged()"></app-number-box-entry>
        </div>
        <div class="p-4">
            <h4 class="font-bold cursor-pointer" (click)="toggleShowAnswers()">Question Answers</h4>
            <div *ngIf="showAnswers" @inOutAnimation>
                <div *ngFor="let a of question.QuestionAnswers" @inOutAnimation class="border-t border-grey-500 pl-4 transition-all">
                    <div *ngIf="a.Active">
                        <app-text-box-entry class="col-span-2 mb-2" [(value)]="a.AnswerText" (valueChange)="questionChanged()"></app-text-box-entry>
                        <div class="grid grid-cols-3 w-1/2 auto-cols-min items-center">
                            <p class=" text-right pr-2 mb-2" *ngIf="!question.ExcludeScoreFromTotal"><span class="text-sm text-grey-900">Compliance Score: </span></p>
                            <app-number-box-entry *ngIf="!question.ExcludeScoreFromTotal" class="col-span-2 mb-2" [(value)]="a.ComplianceScore" (valueChange)="questionChanged()"></app-number-box-entry>
                            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Default?: </span></p>
                            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="a.IsDefault" (checkedChange)="questionChanged()"></app-toggle-switch>
                            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Actionable?: </span></p>
                            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="a.Actionable" (checkedChange)="questionChanged()"></app-toggle-switch>
                            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is N/A?: </span></p>
                            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="a.IsNA" (checkedChange)="questionChanged()"></app-toggle-switch>
                        </div>    
                        <button class="btn-error mb-4 mt-0 w-auto" (click)="removeAnswer(a)">Remove Answer</button>
                    </div>
                </div>
                <button class="btn-primary" (click)="addAnswer()">Add Answer</button>
            </div>
            
        </div>
        <div class="p-4">
            <h4 class="font-bold cursor-pointer" (click)="toggleShowActions()">Standard Actions</h4>
            <div *ngIf="showActions" @inOutAnimation>
                <div *ngFor="let a of question.StandardActions" @inOutAnimation class="border-t border-grey-500 pl-4 transition-all">
                    <div *ngIf="a.Active">
                        <app-text-box-entry class="col-span-2 mb-2" [(value)]="a.StandardEntryText" (valueChange)="questionChanged()"></app-text-box-entry>
                        <div class="grid grid-cols-3 w-1/2 auto-cols-min items-center">
                            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Default?: </span></p>
                            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="a.IsDefault" (checkedChange)="questionChanged()"></app-toggle-switch>
                            <p class=" text-right pr-2 mb-2" ><span class="text-sm text-grey-900">Seq: </span></p>
                            <app-number-box-entry class="col-span-2 mb-2" [(value)]="a.Seq" (valueChange)="questionChanged()"></app-number-box-entry>
                        </div>    
                        <button class="btn-error mb-4 mt-0 w-auto" (click)="removeAction(a)">Remove Action</button>
                    </div>
                </div>
                <button class="btn-primary" (click)="addAction()">Add Action</button>
            </div>
        </div>
        <div class="p-4 hidden">
            <h4 class="font-bold">Standard Comments</h4>
            
        </div>
    </div>
    
    
</div>
