import { DDL } from "../ddl";
import { QuestionAnswer } from "./question-answer";
import { QuestionCompletionInfo } from "./question-completion-info";
import { StandardQuestionEntry } from "./standard-question-entry";

export class Question{
    public AuditID: number;
    public QuestionID: number;
    public VersionID: number;
    public QuestionHeader: string;
    public QuestionBody:string;
    public Seq: number;
    public PageBreak: boolean;
    public QuestionType: DDL;
    public HelpText: string;
    public QuestionNumber: string;
    public Hook: DDL;
    public AllowComments: boolean;
    public AllowNA: boolean;
    public NASelected:boolean = false;
    public Required: boolean;
    public PhotoRequired: boolean;
    public ComplianceLimit: number;
    public MajorLimit: number;
    public ExcludeScoreFromTotal: boolean;
    public DefaultResolutionPeriod: DDL;
    public DefaultUrgentActionResolutionPeriod: DDL;
    public AuthorisationOverride: DDL;
    public CompletionInfo: QuestionCompletionInfo = new QuestionCompletionInfo();
    public QuestionAnswers: QuestionAnswer[] = [];
    public StandardActions: StandardQuestionEntry[]= [];
    public StandardObservations: StandardQuestionEntry[] = [];
    public RepeatStartID: number;
    public IsRepeatEnd:boolean;
    public GUID: string;
    public NextQ:string;
    public PrevQ:string;
    public RepeatQuestionIDs:number[] = [];
    public RepeatSectionID: number = 0;
    public IsReference: boolean;
    public IsBranching:boolean = false;
    public Active: boolean = true;
    public MaxScore: number;
    public BranchedOut: boolean = false;
    //public CurrentAnswer:Object|null;
    constructor(values: Object = {}){
        Object.assign(this, values);
        if(values.hasOwnProperty('QuestionAnswers')){
            this.QuestionAnswers = [];
            ((values as Question)['QuestionAnswers'] as QuestionAnswer[]).forEach(element => {
                this.QuestionAnswers.push(new QuestionAnswer(element));
            });
        }
        if(values.hasOwnProperty('StandardActions')){
            this.StandardActions = [];
            ((values as Question)['StandardActions'] as StandardQuestionEntry[]).forEach(element => {
                this.StandardActions.push(new StandardQuestionEntry(element));
            });
        }
        if(values.hasOwnProperty('StandardQuestionObservations')){
            this.StandardObservations = [];
            ((values as Question)['StandardObservations'] as StandardQuestionEntry[]).forEach(element => {
                this.StandardObservations.push(new StandardQuestionEntry(element));
            });
        }
        if(values.hasOwnProperty('CompletionInfo')){
            this.CompletionInfo = new QuestionCompletionInfo((values as Question)['CompletionInfo'] as QuestionCompletionInfo);
        }
        if(values.hasOwnProperty('QuestionType')){
            this.QuestionType = new DDL((values as Question)['QuestionType'] as DDL) 
         }   
         if(values.hasOwnProperty('Hook')){
            this.Hook = new DDL((values as Question)['Hook'] as DDL) 
         }   
         if(values.hasOwnProperty('DefaultResolutionPeriod')){
            this.DefaultResolutionPeriod = new DDL((values as Question)['DefaultResolutionPeriod'] as DDL) 
         }   
         if(values.hasOwnProperty('DefaultUrgentActionResolutionPeriod')){
            this.DefaultUrgentActionResolutionPeriod = new DDL((values as Question)['DefaultUrgentActionResolutionPeriod'] as DDL) 
         }   
         if(values.hasOwnProperty('AuthorisationOverride')){
            this.AuthorisationOverride = new DDL((values as Question)['AuthorisationOverride'] as DDL) 
         }   
        //this.GUID = crypto.randomUUID();
    }
}