<div class="m-1 md:m-2  h-full flex flex-col justify-between">
  <div class="flex flex-col grow">
    <div class="flex" [ngClass]="{'justify-between':currentQuestion.HelpText.length > 0}">

      <div class="flex flex-col">
        <h2 *ngIf="currentQuestion.QuestionType.ID == 6" class="font-bold">
          {{ currentQuestion.QuestionHeader }}
        </h2>
        <h3 *ngIf="currentQuestion.QuestionType.ID != 6" class="text-xl">
          {{ currentQuestion.QuestionHeader }}
        </h3>
        <p>{{ currentQuestion.QuestionBody }}</p>
      </div>
      <div *ngIf="currentQuestion.HelpText.length > 0" class="ml-1">
        <div class="flex items-center mb-4 mt-1">
          <div
            class=" flex p-1 bg-green-50 rounded-md border-green-700">
            <span 
              class="material-symbols-outlined text-3xl  align-bottom text-green-700 self-center  px-1"
              (click)="changeHelp()">info</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showHelp" class="">
      <div [ngClass]="{'justify-center':showHelp}" class="flex items-center mb-4 mt-1">
        <div [ngClass]="{'w-full p-3 justify-start':showHelp}"
          class=" flex p-1 bg-green-50 rounded-md border-green-700">
          <span [ngClass]="{'md:px-4 px-2 mr-1':showHelp}"
            class="material-symbols-outlined text-3xl  align-bottom text-green-700 self-center  px-1"
            (click)="changeHelp()">info</span>
          <p *ngIf="showHelp" class="self-center">{{ currentQuestion.HelpText }}</p>
        </div>
      </div>
    </div>

    <div [ngSwitch]=" currentQuestion.QuestionType.ID">
      <div *ngSwitchCase="1">
        <app-radio-button *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-radio-button>
        <div class="flex justify-between align-middle">
          <div class="flex mt-4 gap-4">
            <button class="btn-primary relative" (click)="showPhoto()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Photos.length > 0">{{this.currentQuestion.CompletionInfo.Photos.length}}</span></button>
            <button class="btn-primary relative" (click)="showAttachment()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">attach_file</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Attachments.length > 0">{{this.currentQuestion.CompletionInfo.Attachments.length}}</span></button>

          </div>
          <div class="mt-4 flex items-center ml-3 md:ml-0" *ngIf="currentQuestion.AllowNA">
            <label for="" class="mr-2">Not Applicable</label><app-toggle-switch [(checked)]="currentQuestion.NASelected"
              (checkedChange)="updateLocalData()"></app-toggle-switch>
          </div>

        </div>
        <app-question-photo [currentQuestion]="currentQuestion" [showWebcam]="showWebcam"
          (currentQuestionChange)="updateLocalData()"></app-question-photo>
        <app-question-attachment [showAttachments]="showAttachments" [currentQuestion]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-question-attachment>
      </div>
      <div *ngSwitchCase="2">
        <app-drop-down *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-drop-down>
        <div class="flex justify-between align-middle">
          <div class="flex mt-4 gap-4">
            <button class="btn-primary relative" (click)="showPhoto()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Photos.length > 0">{{this.currentQuestion.CompletionInfo.Photos.length}}</span></button>
            <button class="btn-primary relative" (click)="showAttachment()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">attach_file</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Attachments.length > 0">{{this.currentQuestion.CompletionInfo.Attachments.length}}</span></button>

          </div>
          <div class="mt-4 flex items-center" *ngIf="currentQuestion.AllowNA">
            <label for="" class="mr-2">Not Applicable</label><app-toggle-switch [(checked)]="currentQuestion.NASelected"
              (checkedChange)="updateLocalData()"></app-toggle-switch>
          </div>

        </div>
        <app-question-photo [currentQuestion]="currentQuestion" [showWebcam]="showWebcam"
          (currentQuestionChange)="updateLocalData()"></app-question-photo>
        <app-question-attachment [showAttachments]="showAttachments" [currentQuestion]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-question-attachment>
      </div>
      <div *ngSwitchCase="3">
        <app-multi-select *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-multi-select>
        <div class="flex justify-between align-middle">
          <div class="flex mt-4 gap-4">
            <button class="btn-primary relative" (click)="showPhoto()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Photos.length > 0">{{this.currentQuestion.CompletionInfo.Photos.length}}</span></button>
            <button class="btn-primary relative" (click)="showAttachment()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">attach_file</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Attachments.length > 0">{{this.currentQuestion.CompletionInfo.Attachments.length}}</span></button>

          </div>
          <div class="mt-4 flex items-center" *ngIf="currentQuestion.AllowNA">
            <label for="" class="mr-2">Not Applicable</label><app-toggle-switch [(checked)]="currentQuestion.NASelected"
              (checkedChange)="updateLocalData()"></app-toggle-switch>
          </div>

        </div>
        <app-question-photo [currentQuestion]="currentQuestion" [showWebcam]="showWebcam"
          (currentQuestionChange)="updateLocalData()"></app-question-photo>
        <app-question-attachment [showAttachments]="showAttachments" [currentQuestion]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-question-attachment>

      </div>
      <div *ngSwitchCase="4">
        <app-text-entry *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-text-entry>
        <div class="flex justify-between align-middle">
          <div class="flex mt-4 gap-4">
            <button class="btn-primary relative" (click)="showPhoto()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Photos.length > 0">{{this.currentQuestion.CompletionInfo.Photos.length}}</span></button>
            <button class="btn-primary relative" (click)="showAttachment()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">attach_file</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Attachments.length > 0">{{this.currentQuestion.CompletionInfo.Attachments.length}}</span></button>

          </div>
          <div class="mt-4 flex items-center" *ngIf="currentQuestion.AllowNA">
            <label for="" class="mr-2">Not Applicable</label><app-toggle-switch [(checked)]="currentQuestion.NASelected"
              (checkedChange)="updateLocalData()"></app-toggle-switch>
          </div>

        </div>
        <app-question-photo [currentQuestion]="currentQuestion" [showWebcam]="showWebcam"
          (currentQuestionChange)="updateLocalData()"></app-question-photo>
        <app-question-attachment [showAttachments]="showAttachments" [currentQuestion]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-question-attachment>
      </div>
      <div *ngSwitchCase="8">
        <signature-pad class="" [options]="canvasOptions" *ngIf="showCanvas"
          (onEndEvent)="saveCanvas()"></signature-pad>
        <img *ngIf="!showCanvas" [src]="currentQuestion.CompletionInfo.Answer"
          class="w-[250px] h-[150px] md:w-[500px] md:h-[300px]" />
        <div class="mt-4">
          <button *ngIf="showCanvas" class="btn-primary w-[250px] md:w-[500px]" (click)="clearCanvas()">
            Clear
          </button>
          <button *ngIf="!showCanvas" class="btn-primary w-[500px]" (click)="clearCanvas()">
            Reset
          </button>
        </div>
      </div>
      <div *ngSwitchCase="9">
        <app-date-only *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-date-only>
      </div>
      <div *ngSwitchCase="10" class="flex">
        <app-date-time *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-date-time>

      </div>
      <div *ngSwitchCase="11">
        <app-narrative *ngIf="!currentQuestion.NASelected" [(currentQuestion)]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-narrative>
        <div class="flex justify-between align-middle">
          <div class="flex mt-4 gap-4">
            <button class="btn-primary relative" (click)="showPhoto()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">photo_camera</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Photos.length > 0">{{this.currentQuestion.CompletionInfo.Photos.length}}</span></button>
            <button class="btn-primary relative" (click)="showAttachment()"><span
                class="material-symbols-outlined text-3xl mr-1 align-bottom text-standardwhite self-center">attach_file</span>
              <span class="badge"
                *ngIf="this.currentQuestion.CompletionInfo.Attachments.length > 0">{{this.currentQuestion.CompletionInfo.Attachments.length}}</span></button>

          </div>
          <div class="mt-4 flex items-center" *ngIf="currentQuestion.AllowNA">
            <label for="" class="mr-2">Not Applicable</label><app-toggle-switch [(checked)]="currentQuestion.NASelected"
              (checkedChange)="updateLocalData()"></app-toggle-switch>
          </div>

        </div>
        <app-question-photo [currentQuestion]="currentQuestion" [showWebcam]="showWebcam"
          (currentQuestionChange)="updateLocalData()"></app-question-photo>
        <app-question-attachment [showAttachments]="showAttachments" [currentQuestion]="currentQuestion"
          (currentQuestionChange)="updateLocalData()"></app-question-attachment>
      </div>
      <div *ngSwitchCase="12">
        <p>GPS</p>
      </div>
    </div>
    <h5 *ngIf="showAction()" class="text-green-700 mt-5">Non-Compliance</h5>
    <app-question-action [actionUsers]="actionUsers" *ngIf="showAction()" [(currentQuestion)]="currentQuestion"
      (currentQuestionChange)="updateLocalData()"></app-question-action>
  </div>
  <div>
    <div class="my-4" *ngIf="currentQuestion.AllowComments">
      <h5 class="text-green-700">Notes and Observations</h5>
      <textarea rows="5" class="text-box mt-1" placeholder="" [(ngModel)]="currentQuestion.CompletionInfo.Comments"
        (keyup)="updateLocalData()"></textarea>
    </div>
  </div>
</div>