<h2 class="pt-12 pb-4">Audit Questions</h2>

<div *ngFor="let q of audit.Questions let idx = index">
    <div [ngSwitch]="q.QuestionType.ID" *ngIf="q.Active == true">
        <div *ngSwitchCase="1">
            <app-edit-question-selections [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-selections>
        </div>
        <div *ngSwitchCase="2">
            <app-edit-question-selections [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-selections>
        </div>
        <div *ngSwitchCase="3">
            <app-edit-question-selections [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-selections>
        </div>
        <div *ngSwitchCase="4">
            <app-edit-question-basic [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-basic>
        </div>
        <div *ngSwitchCase="5">
            <!-- comment only -->
        </div>
        <div *ngSwitchCase="6">
            <app-edit-question-heading [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-heading>
        </div>
        <div *ngSwitchCase="7">
            <!-- risk matrix -->
        </div>
        <div *ngSwitchCase="8">
            <app-edit-question-basic [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-basic>
        </div>
        <div *ngSwitchCase="9">
            <app-edit-question-basic [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-basic>
        </div>
        <div *ngSwitchCase="10">
            <app-edit-question-basic [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-basic>
        </div>
        <div *ngSwitchCase="11">
            <app-edit-question-basic [question]="audit.Questions[idx]" [answerTypes]="questionTypes" (deleteQuestion)="deleteQuestion($event)"></app-edit-question-basic>
        </div>
    </div>
</div>

<button (click)="addQuestion()" class="btn-primary">Add Question</button>