import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tap } from 'rxjs';
import { AuditTypeResult } from 'src/app/core-module/models/AuditTypes/audit-type-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { Question } from 'src/app/core-module/models/Audits/question';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditService } from 'src/app/core-module/services/audit.service';

@Component({
  selector: 'app-edit-questions',
  templateUrl: './edit-questions.component.html',
  styleUrls: ['./edit-questions.component.scss']
})
export class EditQuestionsComponent {
  @Input() public audit:FlattenedQuestionHolder;
  @Output() auditChange: EventEmitter<FlattenedQuestionHolder> = new EventEmitter<FlattenedQuestionHolder>();

  public questionTypes:DDL[] = [];

  auditChanged(){
    this.auditChange.emit(this.audit);
  }

  constructor(public audits:AuditService){
    audits.getQuestionTypes().pipe(
      tap(res => {
        this.questionTypes = this.questionTypes.concat(res.Results);
      })
    ).subscribe();
  }

  addQuestion(){
    let lastSeq = 1
    if(this.audit.Questions.length != 0){
      lastSeq = this.audit.Questions[this.audit.Questions.length-1].Seq;
    }
    //let lastSeq = this.audit.Questions[this.audit.Questions.length-1].Seq;
    this.audit.Questions.push(new Question({QuestionID: -1, QuestionHeader:"New Question", QuestionType: new DDL({ID: 6}), Seq: lastSeq+1}));
    this.auditChanged();
  }

  deleteQuestion(question: Question){
    question.Active = false;
    this.auditChanged();
  }

}
