<div class="border-t border-grey-500 py-4">
    <div class="flex justify-between items-center">
        <app-text-box-entry class="block cursor-pointer" (click)="toggleShowOptions()" [(value)]="question.QuestionHeader" (valueChange)="questionChanged()" [isBold]="true"></app-text-box-entry>    
        <button class="btn-error w-auto mt-0"><span class="material-symbols-outlined text-sm cursor-pointer px-2" (click)="delQuestion()">delete</span></button>
    </div>
    <div *ngIf="showOptions">
        <div class="grid grid-cols-3 lg:w-1/2 xl:w-1/2 auto-cols-min items-center" @inOutAnimation>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Body: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [isTextArea]="true" [(value)]="question.QuestionBody" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Type: </span></p>
            <app-dropdown-entry class="col-span-2 mb-2" [(value)]="question.QuestionType" (valueChange)="questionChanged()" [options]="answerTypes"></app-dropdown-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Help Text: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [isTextArea]="true" [(value)]="question.HelpText" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Question Number: </span></p>
            <app-text-box-entry class="col-span-2 mb-2" [(value)]="question.QuestionNumber" (valueChange)="questionChanged()"></app-text-box-entry>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Answer Required?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.Required" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Photo Required?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.PhotoRequired" (checkedChange)="questionChanged()"></app-toggle-switch>
            <p class=" text-right pr-2 mb-2"><span class="text-sm text-grey-900">Is Reference?: </span></p>
            <app-toggle-switch  class="col-span-2 mb-2 ml-4" [(checked)]="question.IsReference" (checkedChange)="questionChanged()"></app-toggle-switch>
        </div>
    </div>
    
</div>
